<template>
    <div class="open-album-agent">
        <div class="title">
            <p>בחר לקוח</p>
        </div>

        <div class="search-bar">
            <el-input placeholder="חיפוש" style="height:100%;" v-model="search_string" :input-style="{'background':'var(--secondary)', 'height': '100%', 'color': 'white',
                'font-size': '26px'}"/>
        </div>

        <div class="results-container" v-loading="loading">
            <p v-if="!search_results">ממתין לחיפוש...</p>
                <table id="table" v-if="search_results">
                    <tr>
                        <th>מס</th>
                        <th>לקוח</th>
                        <th>עיר</th>
                        <th>כתובת</th>
                        <th>אלבום</th>
                    </tr>
                    <template v-for="client in search_results" :key="client.makat">
                        <tr>
                            <td>{{client.makat}}</td>
                            <td>{{client.name}}</td>
                            <td>{{client.city}}</td>
                            <td>{{client.adress}}</td>
                            <td>
                                <div class="open-album" @click="lunch_catalog(client)">
                                    <p>הפעל</p>
                                </div>
                            </td>
                        </tr>
                    </template>
                </table>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '../../store';
import { computed, onMounted } from '@vue/runtime-core';
import { projectFirestore, projectFunctions } from '../../firebase/config';
import router from '../../router';
export default {
setup(){
    const loading = ref(false);
    const search_string = ref('');
    const all_clients = ref([]);

    const search_results = ref(computed(() => {
        if(all_clients.value){
            if(!search_string.value){
                return all_clients.value
            }else{
                return all_clients.value.filter(client => {
                    return client.name.includes(search_string.value) || client.makat.includes(search_string.value)
                })
            }
        }
    }));
    
    const get_clients = async () => {
        if(Number(store.state.user.agent) > 0){
            loading.value = true;
            if(store.state.role > 99){
                const clients = await projectFirestore.collection('Clients').get()
                all_clients.value = clients.docs.map(doc => doc.data())
            }else{
                const clients = await projectFunctions.httpsCallable("agents_clients")({agent: {id: store.state.user.agent}})
                all_clients.value = clients.data;
            }
            loading.value = false
        }
    }

    const lunch_catalog = async (client) => {
        store.dispatch("clear_cart");
        store.state.loader = true;
        let assortment = '10001';
        if(client.merakez){
            assortment = client.merakez
            store.state.client_cart.assortment = assortment;
        }
        if(client.price_list_id){
            store.state.user.price_list = client.price_list_id
        }

        store.state.user.assortment = assortment
        store.state.user.client = client.makat
        store.state.user.client_data = await projectFirestore.collection('Clients').doc(client.makat).get().then(doc => {
            return doc.data()
        })

        store.state.client_cart.client = client.makat;

        router.push('/catalog')
    }

    onMounted(() => {
        get_clients();
    })
    return{
        search_string, search_results, lunch_catalog, loading
    }
}
}
</script>

<style scoped>
.open-album-agent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    flex-shrink: 0;
    width: 100%;
    max-width: 730px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary);
    height: 50px;
    font-size: 24px;
    color: var(--yellow);
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.658);
}
.search-bar{
    margin: 10px 0 10px 0;
    width: 100%;
    max-width: 730px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.results-container{
    width: 100%;
    max-width: 730px;
    height: calc(100% - 50px - 50px - 30px);
    color: white;
    overflow-y: auto;
}
#table {
    border-collapse: collapse;
    width: 100%;
    background: white;
    color: black;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
}
#table th{
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--purple);
  color: white;
  position: sticky;
  top: 0;
}
.open-album{
    width: 100%;
    height: 100%;
    background: var(--success);
    padding: 5px;
    border-radius: 3px;
    color: white;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.658);
    cursor: pointer;
}
</style>